<template>
  <v-container grid-list-md pa-0 relative>
    <v-layout row wrap>
      <v-flex xs12>
        <v-card>
          <v-card-title class="title">
            <!-- <v-icon>layers</v-icon> -->
            Ledger Heads
            <v-spacer></v-spacer>
            <add-button :permission="true" @action="aDialog = true">
              New Account Group
            </add-button>
            <add-button :permission="true" @action="addLedger">
              New Ledger
            </add-button>
          </v-card-title>

          <v-card outlined>
            <v-card flat>
              <v-card-title class="title">
                <v-flex xs4>
                  <v-text-field
                    outlined
                    dense
                    hint="At least 3 characters"
                    color="primary"
                    class="pa-0"
                    label="Search By Name"
                    v-model="dataSearch"
                    @input="searchLedgerHead"
                    append-icon="search"
                  />
                </v-flex>
              </v-card-title>
            </v-card>

            <v-data-table
              :headers="headers"
              :items="form.items.data"
              :footer-props="footerProps"
              :options.sync="pagination"
              :server-items-length="form.items.meta.total"
              class="elevation-1"
            >
              <template v-slot:item="{ index, item }">
                <tr>
                  <td>{{ ++index }}</td>
                  <td style="font-weight: bold" class="text-xs-left">
                    {{ item.name }}
                  </td>
                  <td class="text-xs-left">
                    {{
                      item.opening
                        ? item.opening.amount +
                          (item.opening.drcr_type
                            ? " (" +
                              item.opening.drcr_type.capitalizeFirstLetter() +
                              ".)"
                            : "")
                        : 0
                    }}
                  </td>
                  <td class="text-xs-left">{{ item.account_head.name }}</td>
                  <td class="text-xs-left">
                    {{ item.customer ? item.customer.name : "" }}
                    <p>
                      {{ item.customer ? item.customer.primary_mobile : "" }}
                    </p>
                  </td>
                  <td class="text-xs-left">{{ item.description }}</td>
                  <td
                    class="justify-right text-right"
                    v-if="item.allow_delete === 1"
                    style="width: 20%"
                  >
                    <edit-button
                      permission="subject-read"
                      @agree="form.edit(item), editItem(item)"
                    />
                    <delete-button
                      permission="subject-read"
                      @agree="form.delete(item.id)"
                    />
                  </td>
                  <td class="justify-right text-xs-right" v-else>-</td>
                </tr>
              </template>
              <template slot="no-data"> Data not found. </template>
            </v-data-table>
          </v-card>
        </v-card>
      </v-flex>
      <v-dialog v-model="aDialog" persistent max-width="500px">
        <v-card>
          <v-card-title
            class="title primary white--text"
            style="margin-bottom: 20px"
            >Account Group Information</v-card-title
          >
          <v-card-text>
            <v-form
              ref="form"
              @submit.prevent="save"
              @keydown.native="form.errors.clear($event.target.name)"
              @keyup.enter="save"
              lazy-validation
            >
              <v-container grid-list-md>
                <v-layout row wrap>
                  <v-flex xs12>
                    <v-text-field
                      outlined
                      dense
                      v-model="aForm.name"
                      name="name"
                      label="Name"
                      :error-messages="form.errors.get('name')"
                      :height="25"
                      autocomplete="off"
                    />
                  </v-flex>
                  <!--<v-flex xs12>-->
                  <!--<v-select v-model="form.parent_id" name="parent_id" :items="accountHeads"-->
                  <!--label="Select Account Head" :height="25"/>-->
                  <!--</v-flex>-->
                  <v-autocomplete
                    outlined
                    dense
                    v-model="aForm.parent_id"
                    :items="itemsAccountGroup"
                    :search-input.sync="search"
                    hide-no-data
                    item-text="Name"
                    item-value="id"
                    label="Account Group"
                    placeholder="Start typing to Search"
                    return-object
                  ></v-autocomplete>
                  <v-flex xs12 sm12>
                    <v-textarea
                      outlined
                      dense
                      name="description"
                      v-model="aForm.description"
                      label="Description"
                      :error-messages="form.errors.get('description')"
                    />
                  </v-flex>
                </v-layout>
              </v-container>
            </v-form>
          </v-card-text>
          <v-card-actions style="padding-bottom: 20px">
            <v-spacer />
            <v-btn color="error" @click="aDialog = false" outlined
              >Cancel</v-btn
            >
            <v-btn color="warning" @click="saveAccountGroup(true)" outlined
              >Save And Continue</v-btn
            >
            <v-btn color="success" @click="saveAccountGroup(false)" outlined
              >Save</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog v-model="form.dialog" persistent max-width="600px">
        <v-card>
          <v-card-title class="title primary white--text"
            >Ledger Head Information</v-card-title
          >
          <v-card-text style="margin-top: 20px">
            <v-form
              ref="form"
              @submit.prevent="save"
              @keydown.native="form.errors.clear($event.target.name)"
              @keyup.enter="save"
              lazy-validation
            >
              <v-container grid-list-md>
                <v-layout row wrap>
                  <v-form
                    ref="form"
                    @submit.prevent="store"
                    @keydown.native="form.errors.clear($event.target.name)"
                    @keyup.enter="store"
                    lazy-validation
                  >
                    <v-layout wrap>
                      <v-flex xs12 sm6>
                        <v-text-field
                          outlined
                          dense
                          v-model="form.name"
                          name="name"
                          label="Name"
                          :error-messages="form.errors.get('name')"
                          :height="25"
                          autocomplete="off"
                        />
                      </v-flex>
                      <v-flex xs12 sm6 d-flex>
                        <!--<v-select v-model="form.account_head_id" name="account_head_id"-->
                        <!--:items="accountHeads" label="Select Account Group"-->
                        <!--:error-messages="form.errors.get('account_head_id')"-->
                        <!--:height="25"/>-->
                        <v-autocomplete
                          outlined
                          dense
                          v-model="form.account_head_id"
                          :items="itemsAccount"
                          :search-input.sync="searchA"
                          hide-no-data
                          item-text="Name"
                          item-value="id"
                          label="Account Group"
                          placeholder="Start typing to Search"
                          height="25"
                        ></v-autocomplete>
                      </v-flex>

                      <v-flex xs12 sm6 d-flex>
                        <v-select
                          outlined
                          dense
                          v-model="form.drcr"
                          name="drcr"
                          :items="drcr"
                          label="Select Opening Balance DR/CR"
                          :error-messages="form.errors.get('drcr')"
                          :height="25"
                        />
                      </v-flex>
                      <v-flex xs12 sm6>
                        <v-text-field
                          outlined
                          dense
                          v-model="form.opening_balance"
                          name="opening_balance"
                          label="Opening Balance"
                          :error-messages="form.errors.get('opening_balance')"
                          :height="25"
                          type="number"
                          autocomplete="off"
                        />
                      </v-flex>
                      <v-flex xs12 sm12>
                        <v-textarea
                          outlined
                          dense
                          v-model="form.description"
                          name="description"
                          label="Description"
                          :error-messages="form.errors.get('description')"
                          autocomplete="off"
                        />
                      </v-flex>
                      <v-flex xs12 sm6 d-flex>
                        <v-autocomplete
                          outlined
                          dense
                          v-model="form.customer_id"
                          :items="itemsCustomer"
                          :search-input.sync="searchC"
                          hide-no-data
                          item-text="name"
                          item-value="id"
                          label="Customer"
                          placeholder="Start typing to Search"
                          height="25"
                        ></v-autocomplete>
                      </v-flex>
                      <v-flex xs-12 sm6>
                        <v-btn
                          dense
                          color="success"
                          @click="customerAddDialog = true"
                          outlined
                          height="40"
                          >Add Customer
                        </v-btn>
                      </v-flex>
                    </v-layout>
                  </v-form>
                </v-layout>
              </v-container>
            </v-form>
          </v-card-text>
          <v-card-actions style="padding-bottom: 20px">
            <v-spacer />
            <v-btn color="error" @click="form.dialog = false" outlined
              >Cancel</v-btn
            >
            <v-btn color="warning" @click="save(true)" outlined
              >Save And Continue</v-btn
            >
            <v-btn color="success" @click="save(false)" outlined>Save</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog v-model="customerAddDialog" persistent max-width="500px">
        <v-card>
          <v-card-title class="title primary white--text"
            >Customer Information</v-card-title
          >
          <v-card-text>
            <v-form
              ref="form"
              @submit.prevent="save"
              @keydown.native="form.errors.clear($event.target.name)"
              @keyup.enter="save"
              lazy-validation
            >
              <v-container grid-list-md>
                <v-layout row wrap>
                  <v-form
                    ref="form"
                    @submit.prevent="store"
                    @keydown.native="form.errors.clear($event.target.name)"
                    @keyup.enter="store"
                    lazy-validation
                  >
                    <v-layout wrap>
                      <v-flex xs12 sm6>
                        <v-text-field
                          v-model="customerAdd.name"
                          name="name"
                          label="Name"
                          :error-messages="customerAdd.errors.get('name')"
                          :height="25"
                          autocomplete="off"
                        />
                      </v-flex>
                      <v-flex xs12 sm6>
                        <v-text-field
                          v-model="customerAdd.tel_no"
                          name="tel_no"
                          label="Telephone Number"
                          :error-messages="customerAdd.errors.get('tel_no')"
                          :height="25"
                          autocomplete="off"
                        />
                      </v-flex>
                      <v-flex xs12 sm6>
                        <v-text-field
                          v-model="customerAdd.primary_mobile"
                          name="primary_mobile"
                          label="Primary Mobile Number"
                          :error-messages="
                            customerAdd.errors.get('primary_mobile')
                          "
                          :height="25"
                          autocomplete="off"
                        />
                      </v-flex>
                      <v-flex xs12 sm6>
                        <v-text-field
                          v-model="customerAdd.secondary_mobile"
                          name="secondary_mobile"
                          label="Secondary Mobile Number"
                          :error-messages="
                            customerAdd.errors.get('secondary_mobile')
                          "
                          :height="25"
                          autocomplete="off"
                        />
                      </v-flex>
                      <v-flex xs12 sm6>
                        <v-text-field
                          v-model="customerAdd.email"
                          name="email"
                          label="Email"
                          :error-messages="customerAdd.errors.get('email')"
                          :height="25"
                          autocomplete="off"
                        />
                      </v-flex>
                      <v-flex xs12 sm6>
                        <v-text-field
                          v-model="customerAdd.address"
                          name="address"
                          label="Address"
                          :error-messages="customerAdd.errors.get('address')"
                          :height="25"
                          autocomplete="off"
                        />
                      </v-flex>
                      <v-flex xs12 sm6>
                        <v-text-field
                          v-model="customerAdd.fax"
                          name="fax"
                          label="Fax"
                          :error-messages="customerAdd.errors.get('fax')"
                          :height="25"
                          autocomplete="off"
                        />
                      </v-flex>

                      <v-flex xs6>
                        <v-select
                          v-model="customerAdd.pan_vat"
                          name="pan_vat"
                          :items="panVat"
                          label="Select PAN/VAT"
                          :height="25"
                        />
                      </v-flex>
                      <v-flex xs12 sm6>
                        <v-text-field
                          v-model="customerAdd.pan_vat_no"
                          name="pan_vat_no"
                          :label="
                            customerAdd.pan_vat === 'V'
                              ? 'VAT Number '
                              : 'PAN Number'
                          "
                          :error-messages="customerAdd.errors.get('pan_vat_no')"
                          :height="25"
                          autocomplete="off"
                        />
                      </v-flex>
                      <v-flex xs12 sm6>
                        <v-text-field
                          v-model="customerAdd.credit_days"
                          name="credit_days"
                          label="Credit Days"
                          :error-messages="
                            customerAdd.errors.get('credit_days')
                          "
                          :height="25"
                          autocomplete="off"
                        />
                      </v-flex>
                      <v-flex xs12 sm6>
                        <v-text-field
                          v-model="customerAdd.bank_detail.name"
                          name="bank_name"
                          label="Bank Name"
                          :error-messages="customerAdd.errors.get('bank_name')"
                          :height="25"
                          autocomplete="off"
                        />
                      </v-flex>
                      <v-flex xs12 sm6>
                        <v-text-field
                          v-model="customerAdd.bank_detail.account_no"
                          name="account_no"
                          label="Bank Account No"
                          :error-messages="customerAdd.errors.get('account_no')"
                          :height="25"
                          autocomplete="off"
                        />
                      </v-flex>
                    </v-layout>
                  </v-form>
                </v-layout>
              </v-container>
            </v-form>
          </v-card-text>
          <v-card-actions>
            <v-spacer />
            <v-btn color="warning" @click="customerAddDialog = false" outlined
              >Cancel</v-btn
            >
            <v-btn color="success" @click="addCustomer" outlined>Save</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-layout>
  </v-container>
</template>

<script>
import { mapState } from "vuex";
import Form from "@/library/Form";

const NepaliDate = require("nepali-date");
const nd = new NepaliDate();

export default {
  data: () => ({
    form: new Form(
      {
        name: "",
        description: "",
        account_head_id: "",
        customer_id: "",
        opening_balance: 0,
        drcr: "dr",
      },
      "/api/ledger-head"
    ),
    aForm: new Form(
      {
        name: "",
        description: "",
        parent_id: "",
      },
      "/api/account-head"
    ),
    customerAdd: new Form(
      {
        name: "",
        tel_no: "",
        primary_mobile: "",
        secondary_mobile: "",
        email: "",
        fax: "",
        address: "",
        pan_vat: "",
        pan_vat_no: "",
        credit_days: "",
        bank_detail: {
          name: "Mega",
          account_no: "202020",
        },
      },
      "/api/customer"
    ),
    panVat: [
      { value: "P", text: "PAN" },
      { value: "V", text: "VAT" },
    ],
    customerAddDialog: false,
    searchAH: null,
    isLoading: false,
    rowsPerPageItems: [5, 10, 25, 50, 75, 100],
    pagination: {
      rowsPerPage: 10,
    },
    dataSearch: "",
    aDialog: false,
    search: null,
    searchA: null,
    searchC: null,
    entries: [],
    entriesA: [],
    customerSearchData: [],
    accountHeads: [],
    headers: [
      {
        text: "#",
        align: "left",
        sortable: false,
        value: "id",
        width: "4%",
      },
      { text: "Name", value: "name", align: "left", width: "10%" },
      {
        text: "Opening Balance(Last year)",
        value: "description",
        align: "left",
        width: "20%",
      },
      {
        text: "Account Head",
        value: "account_head_id",
        align: "left",
        width: "20%",
      },
      { text: "Cutomer Info", value: "customer", align: "left", width: "10%" },
      {
        text: "Description",
        value: "description",
        align: "left",
      },
      {
        text: "Actions",
        value: "action",
        sortable: false,
        align: "right",
        width: "15%",
      },
    ],
    drcr: [
      { value: "dr", text: "DR" },
      { value: "cr", text: "CR" },
    ],
    valid: true,
    footerProps: {
      "items-per-page-options": [10, 25, 50, 100, -1],
    },
  }),
  mounted: function () {
    this.get();
  },

  computed: {
    ...mapState(["batch"]),
    itemsAccountGroup() {
      return this.entries.map((entry) => {
        return Object.assign({}, entry, { Name: entry.name });
      });
    },
    itemsAccount() {
      return this.entriesA.map((entryA) => {
        return { Name: entryA.name, id: entryA.id, name: entryA.name };
      });
    },
    itemsCustomer() {
      return this.customerSearchData.map((entryC) => {
        return { name: entryC.name, id: entryC.id };
      });
    },
  },

  watch: {
    pagination: function () {
      this.get();
    },
    batch: function (value) {
      this.get();
    },
    // dataSearch: function () {
    //   this.get();
    // },
    search(val) {
      if (!val) return;
      // if (this.isLoading) return;
      this.isLoading = true;
      this.$rest
        .get("/api/account-head?search=" + val)
        .then(({ data }) => {
          this.entries = data.data;
        })
        .catch((err) => {})
        .finally(() => (this.isLoading = false));
    },
    searchA(val) {
      if (!val) return;
      // if (this.isLoading) return;
      if (this.timer) {
        clearTimeout(this.timer);
        this.timer = null;
      }
      this.timer = setTimeout(() => {
        this.isLoading = true;
        this.$rest
          .get("/api/account-head?search=" + val)
          .then(({ data }) => {
            this.entriesA = data.data.map((res) => {
              return { Name: res.name, id: res.id, name: res.name };
            });
          })
          .catch((err) => {})
          .finally(() => (this.isLoading = false));
      }, 500);
    },
    searchC(val) {
      if (!val) return;
      if (this.timer) {
        clearTimeout(this.timer);
        this.timer = null;
      }
      this.timer = setTimeout(() => {
        this.isLoading = true;
        this.$rest
          .get("api/customer?search=" + val)
          .then(({ data }) => {
            this.customerSearchData = data.data.map((res) => {
              return { name: res.name, id: res.id };
            });
          })
          .catch((err) => {})
          .finally(() => (this.isLoading = false));
      }, 500);
    },
  },

  methods: {
    queryString() {
      let json = this.pagination;
      return (
        "?" +
        Object.keys(json)
          .map(function (key) {
            if (![null, undefined].includes(json[key]))
              return (
                encodeURIComponent(key) + "=" + encodeURIComponent(json[key])
              );
          })
          .join("&") +
        "&search=" +
        this.dataSearch
      );
    },

    get(params) {
      let query = [null, undefined].includes(params)
        ? this.queryString()
        : params;
      this.form.get(null, query).then(({ data }) => {
        this.pagination.totalItems = data.meta.total;
        this.accountHeads = data.data.map((item) => ({
          value: item.id,
          text: item.name,
        }));
      });
    },
    searchLedgerHead(v) {
      setTimeout(() => {
        if (v.length > 2 || !v.length) {
          this.get();
        }
      }, 1000);
    },
    save(tag) {
      this.form.closeModal = !tag;
      this.form
        .store()
        .then((res) => {
          this.$events.fire("notification", {
            message: res.data.message,
            status: "success",
          });
        })
        .catch((e) => {
          this.$events.fire("notification", {
            message: e.response.data.message,
            status: "error",
          });
        });
    },

    saveAccountGroup(tag) {
      this.aForm.parent_id = this.aForm.parent_id.id;
      this.aForm.store().then((data) => {
        this.getAccountHeads();
        this.aDialog = tag ? true : false;
      });
    },
    getAccountHeads() {
      this.$rest.get("/api/account-head?rowsPerPage=100").then(({ data }) => {
        this.accountHeads = data.data.map((item) => {
          return { value: item.id, text: item.name };
        });
      });
    },
    editItem(item) {
      this.entriesA = [];
      this.entriesA.push({
        Name: item.account_head.name,
        id: item.account_head.id,
        name: item.account_head.name,
      });
      this.form.account_head_id = item.account_head.id;
      this.form.opening_balance = item.opening ? item.opening.amount : 0;
      this.form.drcr = item.opening ? item.opening.drcr_type : "dr";
    },
    addLedger() {
      this.form.reset();
      this.form.dialog = true;
    },
    addCustomer() {
      this.customerAdd.store().then((res) => {
        this.customerAddDialog = false;
      });
    },
  },
};
</script>
<style lang="scss"></style>
